var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.current)?_c('v-card',{staticClass:"rounded-t-0",attrs:{"loading":_vm.loading,"flat":"","tile":""}},[_c('v-alert',{staticClass:"ma-0 rounded-t-0",attrs:{"type":_vm.current.signed ? 'success' : 'error',"text":"","prominent":!_vm.$vuetify.breakpoint.xs}},[_vm._v(" Aktuelle Lektion:"),_c('br'),_c('strong',[_vm._v(_vm._s(_vm.current.course.title))]),(_vm.current.signed && _vm.current.absences)?_c('span',[_c('br'),_vm._v("Gemeldete Abwesenheiten: "+_vm._s(_vm.current.absences.length)+" ")]):_vm._e()]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","block":"","small":"","to":{
          name: 'AttendanceCheck',
          params: {
            course: _vm.current.course.id,
            date: _vm.current.date,
            period: _vm.current.periods[0].id,
          },
        }}},[_vm._v(_vm._s(_vm.current.signed ? "änderungen machen" : "jetzt erledigen"))])],1)],1):(_vm.pending.length > 0)?_c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-alert',{staticClass:"ma-0",attrs:{"type":"error","text":"","prominent":!_vm.$vuetify.breakpoint.xs}},[_vm._v(" Du hast "+_vm._s(_vm.pending.length)+" pendente Anwesenheitskontrolle"+_vm._s(_vm.pending.length > 1 ? "n" : "")+" ")]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","block":"","small":"","to":_vm.pending.length > 1
            ? { name: 'AttendanceChecksCurrent' }
            : {
                name: 'AttendanceCheck',
                params: {
                  course: _vm.pending[0].course.id,
                  date: _vm.pending[0].date,
                  period: _vm.pending[0].periods[0].id,
                },
              }}},[_vm._v("jetzt erledigen ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }