<template>
  <div>
    <v-card :loading="loading" class="rounded-t-0" flat tile v-if="current">
      <v-alert
        class="ma-0 rounded-t-0"
        :type="current.signed ? 'success' : 'error'"
        text
        :prominent="!$vuetify.breakpoint.xs"
      >
        Aktuelle Lektion:<br /><strong>{{ current.course.title }}</strong
        ><span v-if="current.signed && current.absences"
          ><br />Gemeldete Abwesenheiten: {{ current.absences.length }}
        </span>
      </v-alert>

      <v-divider />
      <v-card-actions>
        <v-btn
          text
          block
          small
          :to="{
            name: 'AttendanceCheck',
            params: {
              course: current.course.id,
              date: current.date,
              period: current.periods[0].id,
            },
          }"
          >{{ current.signed ? "änderungen machen" : "jetzt erledigen" }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-card v-else-if="pending.length > 0" flat tile>
      <v-alert
        class="ma-0"
        type="error"
        text
        :prominent="!$vuetify.breakpoint.xs"
      >
        Du hast {{ pending.length }} pendente Anwesenheitskontrolle{{
          pending.length > 1 ? "n" : ""
        }}
      </v-alert>

      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          text
          block
          small
          :to="
            pending.length > 1
              ? { name: 'AttendanceChecksCurrent' }
              : {
                  name: 'AttendanceCheck',
                  params: {
                    course: pending[0].course.id,
                    date: pending[0].date,
                    period: pending[0].periods[0].id,
                  },
                }
          "
          >jetzt erledigen
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: false,
      items: [],
      loading: false,
      pending: [],
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.items = await this.apiList({
        resource: "absence/attendancechecks",
        query: "filter=active",
      });
      this.current = this.items.find((el) => el.current);
      this.pending = this.items.filter((el) => !el.signed);
      if (this.pending.length > 0 || this.current) {
        this.$emit("loaded");
      }
      this.loading = false;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
